import { postRequestOsiros } from 'Services/http/core__axios';
import Project from '../../../project';
import { getCookie } from 'Services/cookie/core__cookies';
import { generateUUID } from 'Services/global/core__generate-uuid';

export const getTapCardPinRequired = (cardId, gameOptions) => {
  const url = `/fsb-api-rest/osiros/card/`;

  const data = {
    languageId: getCookie('languageId') || 1,
    cardId: cardId,
    kioskName: gameOptions?.kioskname || 'FSB 1',
    kioskLocation: gameOptions?.kiosklocation || '172.21.15.1:9090',
  };
  return postRequestOsiros(url, data);
};

export const authenticateCardAndPin = (cardId, pin, gameOptions) => {
  const url = '/fsb-api-rest/osiros/authenticate';
  const data = {
    clientId: Project.clientId,
    clientSecret: Project.clientSecret,
    uuid: generateUUID(),
    languageId: getCookie('languageId') || 1,
    cardId: cardId,
    pin: pin,
    kioskName: gameOptions?.kioskname || 'FSB 1',
    kioskLocation: gameOptions?.kiosklocation || '172.21.15.1:9090',
  };

  return postRequestOsiros(url, data);
};
