import styled, { css } from 'styled-components';
import theme from 'UI/globals/theme';
import { FONT, BRAND, GREYS, FUNCTIONAL, BACKGROUND } from 'UI/globals/colours';

export const ITC = styled.div`
  flex: ${({ phone }) => (phone ? 4 : 1)};
  margin-bottom: ${({ phone }) => (phone ? 0 : '24px')};
  margin-right: ${({ space }) => (space ? '8px' : 0)};
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Prefix = styled.span`
  position: absolute;
  padding: 10px 0px 10px 8px;
  left: 1px;
  top: 1px;
  bottom: 1px;
  font-size: 14px;
  line-height: 18px;
  z-index: 1;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;

export const Suffix = styled.span`
  position: absolute;
  padding: 10px 8px 10px 0px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  font-size: 14px;
  line-height: 16px;
  z-index: 1;
`;

export const Textbox = styled.input`
  border: ${props => (props.theme.dark ? `none` : `1px solid ${GREYS.doveD}`)};
  font-size: 14px;
  padding: 8px;
  ${({ prefixWidth }) =>
    prefixWidth &&
    css`
      padding-left: ${({ prefixWidth }) => `${prefixWidth + 4}px`};
    `}
  ${({ suffixWidth }) =>
    suffixWidth &&
    css`
      padding-right: ${({ suffixWidth }) => `${suffixWidth + 4}px`};
    `}
  border-radius: 4px;
  color: ${props => (props.theme.dark ? GREYS.white : GREYS.black)};
  font-family: ${theme.fontFamily};
  width: ${props => (props.width ? props.width : '100%')};
  height: 38px;
  background-color: ${props => (props.theme.dark ? GREYS.silver : FONT.light)};
  &:hover {
    border-color: ${props => (props.theme.dark ? `transparent` : GREYS.silver)};
  }
  &:focus {
    border-color: ${GREYS.silver};
    outline: none;
  }
  ::placeholder {
    color: ${GREYS.doveD};
  }
  ${({ inactive }) =>
    inactive &&
    css`
      border: ${props =>
        props.theme.dark
          ? `1px solid ${GREYS.silver}`
          : `1px solid ${GREYS.doveD}`};
      background-color: ${props =>
        props.theme.dark ? GREYS.silverD : GREYS.dove};
      pointer-events: none;
      color: ${props => (props.theme.dark ? FONT.lightMute : GREYS.silver)};
    `}
  ${({ theme }) =>
    theme.dark &&
    css`
      background-color: ${GREYS.silver};
      color: ${GREYS.white};
      border: none;
      ${({ darkTheme }) =>
        darkTheme &&
        css`
          background-color: ${GREYS.doveD};
          color: ${GREYS.black};
        `}
      ${({ inactive }) =>
        inactive &&
        css`
          background-color: ${GREYS.silverD};
          pointer-events: none;
          border: 1px solid ${GREYS.silver};
          color: ${FONT.lightMute};
        `}
    `}
`;

/**
 * Label container
 */
export const LC = styled.div`
  display: flex;
  justify-content: flex-start;
  /* make sure this works, bellow  */
  font-family: ${theme.fontFamily};
  font-size: 12px;
  color: ${props => (props.theme.dark ? GREYS.white : GREYS.black)};
  ${({ theme }) =>
    theme.dark &&
    css`
      color: ${GREYS.white};
    `}
`;

/**
 * label text
 */
export const L = styled.div`
  font-family: ${theme.fontFamily};
  font-size: 12px;
  margin-bottom: 8px;
  color: ${props => (props.theme.dark ? GREYS.white : GREYS.black)};
  ${({ theme }) =>
    theme.dark &&
    css`
      color: ${GREYS.white};
    `}
`;

/**
 * label suffix
 */
export const LS = styled.div`
  font-family: ${theme.fontFamily};
  font-size: 12px;
  margin-bottom: 8px;
  color: ${props => (props.theme.dark ? GREYS.white : GREYS.black)};
  ${({ theme }) =>
    theme.dark &&
    css`
      color: ${GREYS.white};
    `}
`;

/**
 * TB
 */
export const TB = styled.div`
  display: flex;
  gap: 4px;
`;

export const HT = styled(L)`
  transition: all 250ms ease;
  color: ${GREYS.silver};
  text-decoration: underline;
  &:hover {
    color: ${BRAND.primary};
  }
  ${({ theme }) =>
    theme.dark &&
    css`
      color: ${GREYS.doveD};
    `}
`;

export const ValidText = styled(Textbox)`
  border-color: ${FUNCTIONAL.positive};
`;

export const GuideText = styled(Textbox)`
  border-color: ${FUNCTIONAL.guide};
`;

export const ErrorText = styled(Textbox)`
  border-color: ${FUNCTIONAL.negative};
`;

export const WarningText = styled(Textbox)`
  border-color: ${FUNCTIONAL.warning};
  z-index: 1;
`;

export const Info = styled.div`
  font-family: ${theme.fontFamily};
  font-size: 12px;
  margin-top: 4px;
  ${({ error }) =>
    error ? `color: ${FUNCTIONAL.negative}` : `color: ${GREYS.silver};`}
  ${({ showJNStyle }) =>
    showJNStyle &&
    css`
      color: ${GREYS.doveD};
    `}
  ${({ hideInfo }) =>
    hideInfo &&
    css`
      display: none;
    `}
`;

export const StatusInfo = styled(Info)`
  padding-left: 8px;
`;

export const ValidInfo = styled(StatusInfo)`
  color: ${FUNCTIONAL.positive};
`;

export const GuideInfo = styled(StatusInfo)`
  color: ${FUNCTIONAL.guide};
`;

export const ErrorInfo = styled(StatusInfo)`
  color: ${FUNCTIONAL.negative};
`;

export const WarningInfo = styled(StatusInfo)`
  color: ${FUNCTIONAL.warning};
`;

export const IC = styled.div`
  position: relative;
  cursor: pointer;
  top: -30px;
  left: calc(100% - 34px);
  width: 24px;
  height: 14px;
  color: ${BACKGROUND.oddsButtonSelected};
`;
