import PropTypes from 'prop-types';
import React from 'react';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { LoginModal } from './components/LoginModal';
import { PinModal } from './components/PinModal';
import { handleLoginSuccessListener } from '../core__login-store';
import { createCookie } from 'Services/cookie/core__cookies';

/**
 * component holds login logic, renders pin based auth if pinLogin=true
 */
class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      render: false,
    };
  }
  componentDidMount() {
    this.setState({ render: true });

    // listen when data comes form HIO detail form .

    this.HioLoginSuccessListener = PubSub.listen(
      PubsubEvents.HIO_LOGIN_SUCCESS,
      wsData => {
        if (Object.keys(wsData).length > 0) {
          const {
            cusRes,
            loginObject,
            registrationScreen,
            redirectToCasinoPage,
          } = wsData;
          this.props.handleLoginSuccess(
            cusRes,
            loginObject,
            registrationScreen,
            redirectToCasinoPage
          );
        }
      }
    );
  }

  componentWillUnmount() {
    this.HioLoginSuccessListener?.unsubscribe();
    handleLoginSuccessListener?.unsubscribe();
  }

  checkUserAgentHeader = () => {
    if (
      typeof window !== 'undefined' &&
      navigator?.userAgent?.includes('app=fsb')
    ) {
      createCookie('isAndroidApp', true);
      const oldUserAgent = navigator.userAgent;
      Object.defineProperty(navigator, 'userAgent', {
        value: `${oldUserAgent} app=fsb`,
        writable: true,
      });
    }
  };

  render() {
    this.checkUserAgentHeader();
    if (!this.state.render) return <React.Fragment />; //we don't want to sent this popup from server as it appears on user click only
    return this.props.pinLogin ? <PinModal /> : <LoginModal {...this.props} />;
  }
}

Login.propTypes = {
  pinLogin: PropTypes.bool,
  handleLoginSuccess: PropTypes.func,
};

export default Login;
