import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { connect } from 'react-redux';
import createStore, {
  onModalClick,
  fieldChanged,
  callLogin,
  callJoinNow,
  openPasswordModal,
  setRegistrationSuccess,
  setResetSuccess,
  onRegisterModalClick,
  onResetModalClick,
  handleLoginSuccess,
} from './core__login-store';
import Login from './component';
import ScDecoder from 'Services/json/core__decoder';
import { TranslationsProvider } from 'Services/translations/core__translations';
import { translationStrings } from './core__login-translations';

export const Translations = new TranslationsProvider('LoginApp');

class LoginApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }

    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp();
    Translations.setAll(data);
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    const mapStateToProps = state => ({
      url: this.url,
      showModal: state.showModal,
      loginObject: state.loginObject,
      appConfig: this.appConfig,
    });

    const mapDispatchToProps = {
      url: this.url,
      onModalClick: onModalClick,
      fieldChanged: fieldChanged,
      callLogin: callLogin,
      callJoinNow: callJoinNow,
      openPasswordModal: openPasswordModal,
      setRegistrationSuccess: setRegistrationSuccess,
      setResetSuccess: setResetSuccess,
      onRegisterModalClick: onRegisterModalClick,
      onResetModalClick: onResetModalClick,
      handleLoginSuccess: handleLoginSuccess,
    };

    Login.serverFetch = {
      url: this.url,
      onModalClick,
      fieldChanged,
      callLogin,
      callJoinNow,
      openPasswordModal,
      setRegistrationSuccess,
      setResetSuccess,
      onRegisterModalClick,
      onResetModalClick,
      createStore: createStore,
      fetchData: () => dispatch => {
        return new Promise(resolve => {
          dispatch({ type: 'DO_NOTHING' });
          resolve();
        });
      },
      strToTranslate: translationStrings,
      handleLoginSuccess,
    };

    return connect(mapStateToProps, mapDispatchToProps)(Login);
  }
}

export default LoginApp;
