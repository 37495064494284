export const translationStrings = [
  'text.registration.complete',
  'text.make.first.deposit',
  'text.close',
  'text.login',
  'text.email',
  'user.username',
  'user.password',
  'text.forgot.password',
  'text.log.in',
  'text.or',
  'text.join',
  'text.join.now',
  'text.need.help',
  'text.contact.us',
  'text.browse.website',
  'error.account.timeout',
  'error.password.authentication',
  'valid.password',
  'valid.username',
  'text.malfunction.voids',
  'text.registration.welcome',
  'text.reset.welcome',
  'text.reset.successfully',
  'login.checkbox',
  'mfa.button.verify',
  'text.registration.identity',
  'text.registration.welcome',
  'mfa.title',
  'mfa.info',
  'mfa.popup.title',
  'mfa.code.incorrect',
  'mfa.code.sent.to',
  'mfa.code.not.received',
  'mfa.button.verify',
  'mfa.button.ok',
  'mfa.resend',
  'mfa.twofa.disabled',
  'mfa.twofa.enabled',
  'api.error.account.error.account.closed',
  'text.cell.phone.number',
  'text.registration.success',
  'text.withdraw.back',
  'text.registration.welcome.text',
  'text.personal.verification',
  'text.personal.verification.text',
  'text.upload',
  'text.support.questions',
  'text.support.contact',
  'text.support.contact.link',
  'text.validphonenumber',
  'text.deposit.claim.bonus',
];
