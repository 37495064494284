import styled, { keyframes } from 'styled-components';
import Icons from 'UI/globals/Icons';

export const iconName = 'icon-Loading---Spinner';

export const size = 22;

export const SpinnerWrapper = styled.div`
  text-align: center;
  padding: ${({ noPadding }) => (noPadding ? '0' : '16px')};
`;

const spin = keyframes`
  100% { transform: rotate(360deg); }
`;

export const ColorInheritIcon = styled(Icons)`
  color: inherit;
  animation: ${spin} 4s linear infinite;
  display: inline-block;
`;
