import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  AST,
  BKSP,
  AS,
  BT,
  CHK,
  T,
} from 'CORE__UI/apps/LoginApp/core__pinInput'; //imported from core to not break reactbet
import { Translations } from '../../../core__login-app';

/**
 * popup to handle pin input and sent output to parent
 * @param {Function} setPin
 * @param {Function} submit
 * @returns
 */
export const PinInput = ({ setPin, submit }) => {
  const [asteriks, setAsteriks] = useState(' ');
  const rows = [`1|2|3|4|5|submit`, `6|7|8|9|0|backspace`];
  //send input data from keyboard to target
  const sendToInput = key => {
    const el = document.getElementById('pin-inpt');
    if (el) {
      let str = el.value;
      if (key === 'backspace') {
        str = str.substr(0, str.length - 1);
      } else if (key === 'clear') {
        str = '';
      } else {
        str += key.toUpperCase();
      }
      el.value = str;

      const stars = new Array(str.length).fill('*'); //to show stars at the input box
      setAsteriks(stars);

      //to set pin in parent component
      setPin(str);
    }
  };
  return (
    <div>
      <div>
        <AS>
          <AST>{asteriks}</AST>
        </AS>
        <input type="hidden" id="pin-inpt" />
      </div>
      {rows.map((row, index) => {
        return (
          <div key={index}>
            {row.split('|').map(key => {
              return (
                <BT
                  key={key}
                  onClick={() => {
                    if (key === 'submit') {
                      submit();
                      sendToInput('clear');
                    } else {
                      sendToInput(key);
                    }
                  }}
                  className={key}
                >
                  {key === 'submit' && (
                    <React.Fragment>
                      <CHK />
                      &nbsp;
                    </React.Fragment>
                  )}
                  {key === 'backspace' && (
                    <React.Fragment>
                      <BKSP />
                      &nbsp;
                    </React.Fragment>
                  )}

                  {key !== 'submit' && key !== 'backspace' && key}
                </BT>
              );
            })}
          </div>
        );
      })}
      <T>{Translations.get('text.malfunction.voids')}</T>
    </div>
  );
};

PinInput.propTypes = {
  setPin: PropTypes.func,
  submit: PropTypes.func,
};
