import styled from 'styled-components';
import { BRAND, GREYS } from 'UI/globals/colours';

export const PPC = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
export const PPO = styled.div`
  position: abosolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${GREYS.silver};
  opacity: 0.5;
`;
export const PP = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 0 42px;
  background-color: ${GREYS.white};
  border-radius: 18px;
`;
export const PT = styled.p`
  color: ${BRAND.primary};
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 24px;
  text-align: center;
  margin-top: 30px;
`;
export const CardSimulateButton = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;

export const CLS = styled.div`
  width: 22px;
  height: 22px;
  position: absolute;
  right: 42px;
  top: 30px;
  &:after {
    content: '';
    height: 24px;
    border-left: 2px solid ${BRAND.primary};
    position: absolute;
    transform: rotate(45deg);
    left: 12px;
  }
  &:before {
    content: '';
    height: 24px;
    border-left: 2px solid ${BRAND.primary};
    position: absolute;
    transform: rotate(-45deg);
    left: 12px;
  }
`;
