import React from 'react';
import PropTypes from 'prop-types';
import {
  iconName,
  size,
  SpinnerWrapper,
  ColorInheritIcon,
} from 'UI/spinner/ColorSpinnerStyles';

export const ColorSpinner = ({ className, noPadding, sizeOverride }) => {
  return (
    <SpinnerWrapper
      className={className}
      noPadding={noPadding}
      data-testid="spinner"
    >
      <ColorInheritIcon
        iconName={iconName}
        size={sizeOverride || size}
        spinning={true}
      />
    </SpinnerWrapper>
  );
};
ColorSpinner.propTypes = {
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  sizeOverride: PropTypes.number,
};

export default ColorSpinner;
