import React, { useCallback, useEffect, useState } from 'react';
import {
  authenticateCardAndPin,
  getTapCardPinRequired,
} from '../../../core__login-osiros-api';
import { PinInput } from '.';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import Spinner from 'UI/spinner/Spinner';
import { CLS, PP, PPC, PPO, PT } from 'CORE__UI/apps/LoginApp/core__pinModal'; //imported from core not to break reactbet

/**
 * Container component for pin input and tap card functionality
 * @returns react component
 */
export const PinModal = () => {
  const [cardId, setCardId] = useState(0);
  const [showPinPopup, setShowPinPopup] = useState(false);
  const [pin, setPin] = useState('1234');
  const [title, setTitle] = useState('Enter your PIN code');
  const [showLoader, setShowLoader] = useState(false);
  const [gameOptions, setGameOptions] = useState({});

  useEffect(() => {
    //bind card listener event from

    const cardInsertedListener = PubSub.listen(
      PubsubEvents.CARD_INSERTED,
      ({ card_number, gameOptions, isLoggedIn }) => {
        setGameOptions(gameOptions);
        processCardRead(card_number, gameOptions, isLoggedIn);
      }
    );

    return () => {
      cardInsertedListener?.unsubscribe();
    };
  }, [processCardRead]);

  const processCardRead = useCallback(
    (cardId, gameOptions, isLoggedIn) => {
      //do nothing if already logged in
      //no need to open popup if logged in
      if (isLoggedIn) {
        return false;
      }
      setCardId(cardId);

      //remove after qa
      getTapCardPinRequired(cardId, gameOptions).then(response => {
        if (
          response?.status?.returnCode === 'SUCCESS' &&
          response.pinRequired
        ) {
          setShowPinPopup(true);
        } else if (
          response?.status?.returnCode === 'SUCCESS' &&
          response.pinRequired === false
        ) {
          submit(cardId, gameOptions);
        }
      });
    },
    [submit]
  );

  const submit = useCallback(
    async (cId, gOpt) => {
      if (!gOpt) {
        gOpt = gameOptions;
      }
      if (!cId) {
        cId = cardId;
      }

      setShowLoader(true);
      const authResult = await authenticateCardAndPin(cId, pin, gOpt);
      setShowLoader(false);

      if (authResult?.customer) {
        //do a pubsub here
        PubSub.emit(PubsubEvents.SESSION_CREATE, authResult?.customer);
        setShowPinPopup(false);
      } else if (authResult?.statusCode !== 200) {
        setTitle(authResult?.statusText);
        setTimeout(() => {
          setTitle(title);
        }, 3000);
      } else {
        setTitle('Wrong PIN entered');
        setTimeout(() => {
          setTitle(title);
        }, 3000);
      }
    },
    [cardId, gameOptions, pin, title]
  );

  return (
    <React.Fragment>
      {showPinPopup && (
        <PPC>
          <PPO></PPO>
          <PP>
            <PT>
              {title} &nbsp;
              {showLoader && (
                <Spinner
                  display={showLoader}
                  width={30}
                  style={{ display: 'inline' }}
                />
              )}
            </PT>
            <CLS
              onClick={() => {
                setShowPinPopup(false);
              }}
            />

            <PinInput setPin={setPin} submit={submit}></PinInput>
          </PP>
        </PPC>
      )}
    </React.Fragment>
  );
};
