import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import TRACKING_CONSTANTS from 'Services/constants/core__tracking';
import { getComponent } from 'Services/core__imports';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import {
  ITC as ITC_LOCAL,
  InputWrapper as InputWrapper_LOCAL,
  Prefix as Prefix_LOCAL,
  Suffix as Suffix_LOCAL,
  Textbox as Textbox_LOCAL,
  L as L_LOCAL,
  LS as LS_LOCAL,
  LC as LC_LOCAL,
  HT as HT_LOCAL,
  ValidText as ValidText_LOCAL,
  GuideText as GuideText_LOCAL,
  ErrorText as ErrorText_LOCAL,
  WarningText as WarningText_LOCAL,
  Info as Info_LOCAL,
  ValidInfo as ValidInfo_LOCAL,
  GuideInfo as GuideInfo_LOCAL,
  ErrorInfo as ErrorInfo_LOCAL,
  WarningInfo as WarningInfo_LOCAL,
  TB as TB_LOCAL,
  IC as IC_LOCAL,
} from 'UI/forms/InputTextStyles';

import {
  ITC as ITC_CORE,
  InputWrapper as InputWrapper_CORE,
  Prefix as Prefix_CORE,
  Suffix as Suffix_CORE,
  Textbox as Textbox_CORE,
  L as L_CORE,
  LS as LS_CORE,
  LC as LC_CORE,
  HT as HT_CORE,
  ValidText as ValidText_CORE,
  GuideText as GuideText_CORE,
  ErrorText as ErrorText_CORE,
  WarningText as WarningText_CORE,
  Info as Info_CORE,
  ValidInfo as ValidInfo_CORE,
  GuideInfo as GuideInfo_CORE,
  ErrorInfo as ErrorInfo_CORE,
  WarningInfo as WarningInfo_CORE,
  TB as TB_CORE,
  IC as IC_CORE,
} from 'CORE__UI/forms/InputText/core__inputTextStyles';

const ITC = getComponent(ITC_LOCAL, ITC_CORE);
const InputWrapper = getComponent(InputWrapper_LOCAL, InputWrapper_CORE);
const Prefix = getComponent(Prefix_LOCAL, Prefix_CORE);
const Suffix = getComponent(Suffix_LOCAL, Suffix_CORE);
const Textbox = getComponent(Textbox_LOCAL, Textbox_CORE);
const L = getComponent(L_LOCAL, L_CORE);
const LS = getComponent(LS_LOCAL, LS_CORE);
const LC = getComponent(LC_LOCAL, LC_CORE);
const HT = getComponent(HT_LOCAL, HT_CORE);
const ValidText = getComponent(ValidText_LOCAL, ValidText_CORE);
const GuideText = getComponent(GuideText_LOCAL, GuideText_CORE);
const ErrorText = getComponent(ErrorText_LOCAL, ErrorText_CORE);
const WarningText = getComponent(WarningText_LOCAL, WarningText_CORE);
const Info = getComponent(Info_LOCAL, Info_CORE);
const ValidInfo = getComponent(ValidInfo_LOCAL, ValidInfo_CORE);
const GuideInfo = getComponent(GuideInfo_LOCAL, GuideInfo_CORE);
const ErrorInfo = getComponent(ErrorInfo_LOCAL, ErrorInfo_CORE);
const WarningInfo = getComponent(WarningInfo_LOCAL, WarningInfo_CORE);
const TB = getComponent(TB_LOCAL, TB_CORE);
const IC = getComponent(IC_LOCAL, IC_CORE);

const InputStatus = status => {
  switch (status) {
    case 'valid':
      return ValidText;
    case 'guide':
      return GuideText;
    case 'error':
      return ErrorText;
    case 'warning':
      return WarningText;
    default:
      return;
  }
};

/* TODO: add icons once available */

const InfoStatus = status => {
  switch (status) {
    case 'valid':
      return ValidInfo;
    case 'guide':
      return GuideInfo;
    case 'error':
      return ErrorInfo;
    case 'warning':
      return WarningInfo;
    default:
      return;
  }
};

const InputText = ({
  refProp,
  placeholder,
  status,
  label,
  labelSuffix,
  helperText,
  helperLink,
  info,
  type,
  hideDatePicker,
  phone,
  space,
  onClick,
  showJNStyle,
  name,
  autoComplete,
  isbetgtmtag,
  recoverPassword,
  prefix,
  suffix,
  hideAs,
  tabIndex,
  withIcon,
  defaultIcon,
  toggleIcon,
  defaultIconState,
  iconClick,
  isSafari,
  iconSize,
  hideInfo,
  maxLength,
  inactive,
  color,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const inputFieldClicked = useCallback(
    (checkFocus, name, isbetgtmtag) => {
      setFocus(checkFocus);
      if (name === GLOBAL_CONSTANTS.USERNAME && !isTouched && isbetgtmtag) {
        PubSub.emit(PubsubEvents.THIRD_PARTY_TRACKING, {
          event: TRACKING_CONSTANTS.REGISTRATION_STARTED,
          data: {},
        });
        setIsTouched(true);
      }
    },
    [isTouched] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (props.focus) inputFieldClicked(props.focus, name, isbetgtmtag);
  }, [props.focus, name, isbetgtmtag, inputFieldClicked]);

  const prefixRef = useRef();
  const suffixRef = useRef();
  const [prefixWidth, setPrefixWidth] = useState(0);
  const [suffixWidth, setSuffixWidth] = useState(0);
  setTimeout(() => {
    setPrefixWidth(prefixRef.current?.clientWidth);
    setSuffixWidth(suffixRef.current?.clientWidth);
  }, 1);
  return (
    <ITC phone={phone} space={space} {...props}>
      <TB {...props}>
        {label && (
          <LC {...props}>
            <L>{label}</L>
          </LC>
        )}
        {helperText && (
          <HT>
            <a
              onClick={() => {
                onClick();
              }}
              href={helperLink ? helperLink : '#'}
            >
              {helperText}
            </a>{' '}
          </HT>
        )}
        {labelSuffix && (
          <LS recoverPassword={recoverPassword}>{labelSuffix}</LS>
        )}
      </TB>
      <InputWrapper {...props}>
        {prefix && <Prefix ref={prefixRef}>{prefix}</Prefix>}
        <Textbox
          data-testid="Textbox"
          tabIndex={tabIndex}
          ref={refProp}
          name={name}
          autoFocus={focus}
          onFocus={() => inputFieldClicked(true, name, isbetgtmtag)}
          type={type}
          hideDatePicker={hideDatePicker}
          as={!hideAs && InputStatus(status)}
          localStatus={hideAs && status}
          placeholder={placeholder}
          autoComplete={autoComplete}
          prefixWidth={prefixWidth}
          suffixWidth={suffixWidth}
          onWheel={event => type === 'number' && event.target.blur()}
          maxLength={maxLength || 524288}
          inactive={inactive}
          {...props}
        />
        {withIcon && (
          <IC onClick={iconClick} isSafari={isSafari}>
            {defaultIconState ? (
              <I
                iconName={getIcon(defaultIcon)}
                size={iconSize}
                color={color}
              />
            ) : (
              <I iconName={getIcon(toggleIcon)} size={iconSize} />
            )}
          </IC>
        )}
        {suffix && <Suffix ref={suffixRef}>{suffix}</Suffix>}
      </InputWrapper>

      {info && (
        <Info
          showJNStyle={showJNStyle}
          hideInfo={hideInfo}
          as={InfoStatus(status)}
        >
          {info}
        </Info>
      )}
    </ITC>
  );
};

InputText.propTypes = {
  /**
   * If it has a ref to the input
   */
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  /**
   * If it has status
   */
  status: PropTypes.oneOf(['valid', 'guide', 'error', 'warning', 'null']),
  /**
   * Label
   */
  label: PropTypes.string,
  labelSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * helper text
   */
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * type text
   */
  type: PropTypes.string,
  flex: PropTypes.number,
  helperText: PropTypes.string,
  name: PropTypes.string,
  helperLink: PropTypes.string,
  phone: PropTypes.bool,
  space: PropTypes.bool,
  isbetgtmtag: PropTypes.bool,
  onClick: PropTypes.func,
  showJNStyle: PropTypes.bool,
  autoComplete: PropTypes.string,
  recoverPassword: PropTypes.bool,
  theme: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  focus: PropTypes.bool,
  hideAs: PropTypes.bool,
  tabIndex: PropTypes.string,
  withIcon: PropTypes.bool,
  defaultIcon: PropTypes.string,
  toggleIcon: PropTypes.string,
  defaultIconState: PropTypes.bool,
  iconClick: PropTypes.func,
  isSafari: PropTypes.bool,
  iconSize: PropTypes.number,
  hideInfo: PropTypes.bool,
  maxLength: PropTypes.number,
  inactive: PropTypes.bool,
  color: PropTypes.string,
  hideDatePicker: PropTypes.bool,
};

InputText.defaultProps = {
  status: 'null',
  onClick: () => {},
  label: '', // if we don`t pass label , label field should be hidden
  info: null,
  placeholder: null,
  type: 'text',
};

export default withTheme(InputText);
