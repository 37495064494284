import styled from 'styled-components';
import { SHADOW, BRAND, FUNCTIONAL, GREYS } from 'UI/globals/colours';

export const Button = styled.button`
  background-color: transparent;
  box-shadow: none;
  border: none;
  text-shadow: none;
`;
export const BT = styled(Button)`
  width: 58px;
  height: 58px;
  color: ${BRAND.primary};
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 24px;
  text-align: center;
  display: inline-block;
  box-shadow: ${SHADOW};
  border-radius: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-right: 0;
  }
  &.backspace {
    background-color: ${FUNCTIONAL.negative};
  }
  &.submit {
    background-color: ${FUNCTIONAL.positive};
  }
`;

export const AS = styled.div`
  height: 77px;
  box-shadow: ${SHADOW};
  border-radius: 10px;
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const AST = styled.div`
  color: ${GREYS.doveD};
  font-size: 36px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -40%);
`;

export const CHK = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  height: 24px;
  width: 12px;
  border-bottom: 2px solid ${GREYS.white};
  border-right: 2px solid ${GREYS.white};
`;
export const BKSP = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  height: 24px;
  width: 24px;
  border-bottom: 2px solid ${GREYS.white};
  border-right: 2px solid ${GREYS.white};
  display: inline-block;
  transform: translate(-25%, -50%) rotate(135deg);
  height: 20px;
  width: 20px;
  border-bottom: 2px solid ${GREYS.white};
  border-right: 2px solid ${GREYS.white};
  transform-origin: center center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: center center;
`;

export const T = styled.p`
  color: ${BRAND.primary};
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 8px;
  margin-bottom: 30px;
`;
