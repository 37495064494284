import {
  Textbox,
  L,
  HT,
  ValidText,
  GuideText,
  ErrorText,
  WarningText,
  IC,
} from 'CORE__UI/forms/InputText/core__inputTextStyles';
import styled, { css } from 'styled-components';
import { FONT, GREYS, BRAND } from '../globals/colours';
const L_GDWN = styled(L)`
  color: ${GREYS.white};
`;
const Textbox_GDWN = styled(Textbox)`
  background-color: ${FONT.dark};
  color: ${GREYS.white};
  border: none;
  ${({ darkTheme }) =>
    darkTheme &&
    css`
      background-color: ${GREYS.doveD};
      color: ${GREYS.black};
    `}
  ${({ inactive }) =>
    inactive &&
    css`
      background-color: ${GREYS.silverD};
      pointer-events: none;
      border: 1px solid ${GREYS.silver};
      color: ${GREYS.doveD};
    `}
  
  ::placeholder {
    color: ${GREYS.doveD};
    opacity: 1;
  }
`;
const HT_GDWN = styled(HT)`
  color: ${GREYS.doveD};
`;

const IC_EX = styled(IC)`
  color: ${BRAND.secondary};
`;

export {
  Textbox_GDWN as Textbox,
  L_GDWN as L,
  HT_GDWN as HT,
  ValidText,
  GuideText,
  ErrorText,
  WarningText,
  IC_EX as IC,
};
