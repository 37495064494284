/**
 * Generates a bool flag to check whether the first digit should be removed.
 * The conditions are whether it exists, the input matches the value input
 * and that the first digit is the same. The first digit is always a 0.
 * @param {object} e event
 * @param {number | null} SACorrectionLength length when to call correction
 * @returns true if conditions are met, false otherwise
 */
export const zeroCorrectionFlag = (e, SACorrectionLength) => {
  // Check South Africa exists
  if (!SACorrectionLength) return false;

  // Check length is the value entered from the cms
  return (
    e.target.value.length === SACorrectionLength && e.target.value[0] === '0'
  );
};
